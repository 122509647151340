import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import CurrencyInput from 'react-currency-input-field'
import axios from 'axios'
import { useEffectOnce } from 'usehooks-ts'

import { useFormStore } from '../../../stores/form.store'
import { schemaIngresos } from '../../../validation/steps'
import { AutoComplete, DateInput } from '../../../components'
import { meiliSearch } from '../../../utils'

import { type Solicitante } from '../../../types/index'
import { useWizard } from 'react-use-wizard'

type FormData = Pick<Solicitante,
'CiudadResidencia' |
'Actividad' |
'FechaInicioTrabajoActual' |
'FechaInicioTrabajoAnterior' |
'FechaFinTrabajoAnterior' |
'ContinuidadLaboral' |
'ComercioLocal' |
'DataFinanciera'
>

interface Actividad {
  ciiu_code: string
  description: string
}

export const Ingresos = (): JSX.Element => {
  const updateSolicitante = useFormStore(state => state.updateSolicitante)
  const addIngresos = useFormStore(state => state.addIngresos)
  const currentSolicitante = useFormStore(state => state.getCurrentSolicitante())
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [actividades, setActividades] = useState<Actividad[]>([])

  const { handleStep } = useWizard()

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
    handleSubmit,
    setError,
    reset
  } = useForm<FormData>({
    resolver: joiResolver(schemaIngresos),
    defaultValues: currentSolicitante
  })

  const isComerciante = watch('ComercioLocal')

  const onSubmit: SubmitHandler<FormData> = async (data): Promise<void> => {
    const { DataFinanciera: { Ingresos }, ...infoSolicitante } = data
    updateSolicitante(infoSolicitante)
    addIngresos(Ingresos)
  }

  const handleSearchCiudades = async (searchTerm: string): Promise<void> => {
    if (!searchTerm) return

    const results = await meiliSearch<Array<{ name: string }>>({
      index: 'scotia_ciudades',
      attributesToRetrieve: 'name',
      attributesToSearchOn: 'name',
      searchTerm
    })
    const data = results.map((e) => e.name)
    setSuggestions(data)
  }

  useEffectOnce(() => {
    const getActividades = async (): Promise<void> => {
      const { data: { data } } = await axios.get<{ data: Actividad[] }>(`${import.meta.env.VITE_API_COTIZADOR}/ciiuSummary`)
      setActividades(data)
    }

    void getActividades()
  })

  handleStep(async () => {
    await new Promise((resolve) => {
      const customValidation = (): void => {
        const fechaInicioTrabajoActual = getValues('FechaInicioTrabajoActual')
        const fechaFinTrabajoAnterior = getValues('FechaFinTrabajoAnterior')
        const fechaInicioTrabajoAnterior = getValues('FechaInicioTrabajoAnterior')

        const tiempoLaborando = dayjs().diff(dayjs(fechaInicioTrabajoActual), 'months')

        // Validacion Comerciante

        if (isComerciante && (tiempoLaborando < 12)) {
          setError('FechaInicioTrabajoActual', {
            type: 'custom',
            message: 'Mínimo de 12 meses de operación como comerciante.'
          })
          throw new Error('Continuidad Laboral')
        }

        const tiempoLaborandoAnteriorTrabajo = dayjs(fechaInicioTrabajoAnterior)
          .diff(fechaFinTrabajoAnterior, 'months')

        const periodoCesantia = dayjs(fechaInicioTrabajoAnterior)
          .diff(dayjs(fechaInicioTrabajoActual), 'months')

        if ((tiempoLaborando < 6) && (tiempoLaborandoAnteriorTrabajo === 0)) {
          setError('FechaInicioTrabajoActual', {
            type: 'custom',
            message: 'Menos de 6 meses en el trabajo, aplica para Continuidad Laboral.'
          })

          setValue('ContinuidadLaboral', true)
          throw new Error('Continuidad Laboral')
        }

        if (periodoCesantia > 3) {
          setError('FechaInicioTrabajoActual', {
            type: 'custom',
            message: 'No más de 3 meses de desempleo para aplicar a Continuidad Laboral.'
          })

          throw new Error('Continuidad Laboral')
        }

        if ((tiempoLaborandoAnteriorTrabajo + tiempoLaborando) < 6) {
          setError('FechaInicioTrabajoActual', {
            type: 'custom',
            message: 'Requiere al menos 6 meses de experiencia laboral continua.'
          })

          setValue('ContinuidadLaboral', true)
          throw new Error('Continuidad Laboral')
        }
      }

      customValidation()

      void handleSubmit(
        async (data) => {
          onSubmit(data)
          resolve(data)
        })()
    })
  })

  useEffect(() => {
    if (currentSolicitante) {
      reset(currentSolicitante)
    }
  }, [currentSolicitante])

  return (
    <div className='min-h-[80%] py-8 px-10'>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

        <div className="form-control w-full">
          <span className='label-text font-bold text-md mb-2'>¿Dónde resides? *</span>

          <AutoComplete
            defaultValue={ getValues('CiudadResidencia') }
            items={suggestions}
            onInput={handleSearchCiudades}
            onSelect={(val) => { setValue('CiudadResidencia', val) } }
          />

          {
            errors.CiudadResidencia &&
            <div className="label">
              <span className="label-text-alt text-error">
                  {errors.CiudadResidencia.message}
              </span>
            </div>
          }
        </div>

        <div className="form-control w-full">
          <span className='label-text font-bold text-md mb-2'>¿Cuál es tu Ocupación? *</span>
          <select
            defaultValue={ isComerciante ? 'S' : 'N' }
            onChange={({ target: { value } }) => {
              setValue('ComercioLocal', value === 'S')
            }}
            className="select select-bordered w-full bg-white">
            <option value="N" >ASALARIADO / PENSIONADO</option>
            <option value="S" >COMERCIANTE</option>
          </select>
        </div>

        <div className={cn('form-control w-full',
          { 'hidden': !isComerciante })}>
          <span className='label-text font-bold text-sm mb-2'>¿Actividad principal de tu empresa? *</span>
          <select
            {...register('Actividad')}
            className="select select-bordered bg-white w-full">
            {
              actividades.map(({ ciiu_code, description }, index) => (
                <option
                  key={`actividad_option_${index}`}
                  value={ciiu_code}
                >{description}</option>
              ))
            }
          </select>

          {
            errors.Actividad &&
            <div className="label">
              <span className="label-text-alt text-error">
                  {errors.Actividad.message}
              </span>
            </div>
          }
        </div>

        <div className="form-control w-full">
          <span className='label-text font-bold text-sm mb-2'>
            Fecha inicio {
              isComerciante
                ? 'operaciones'
                : 'trabajo actual'
            } *
          </span>

          <DateInput
            maxDate={dayjs().toDate()}
            className='input input-bordered bg-white w-full'
            selected={dayjs(watch('FechaInicioTrabajoActual')).toDate()}
            onChange={ e => { setValue('FechaInicioTrabajoActual', e) } }
            placeholderText='01/02/2016'
          />

          {
            errors.FechaInicioTrabajoActual &&
            <div className="label">
              <span className="label-text-alt text-error">
                  {errors.FechaInicioTrabajoActual.message}
              </span>
            </div>
          }
        </div>

        <div className="form-control w-full">
          <span className='label-text font-bold text-sm mb-2'>Ingreso Mensual *</span>

          <CurrencyInput
            defaultValue={getValues('DataFinanciera.Ingresos.0.Total')}
            placeholder='$ 2,500,000.00'
            decimalsLimit={2}
            allowNegativeValue={false}
            prefix='$ '
            className='input input-bordered w-full bg-white'
            onValueChange={(value) => {
              const input = value ? +value : 0
              setValue('DataFinanciera.Ingresos.0', {
                Total: input,
                Descripcion: isComerciante ? 'UTILIDAD MENSUAL' : 'SALARIO'
              })
            }}
          />

          {
            errors.DataFinanciera?.Ingresos &&
            <div className="label">
              <span className="label-text-alt text-error">
                {errors.DataFinanciera?.Ingresos[0]?.Total?.message}
              </span>
            </div>
          }
        </div>

        <div className={
          cn('col-span-1 md:col-span-2 flex flex-col items-start', {
            'invisible': isComerciante
          })
        }>
          <h4 className='mt-2'>
            ¿Has cambiado de trabajo dentro de los últimos 6 meses?
          </h4>

          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text font-bold text-md mr-3">No</span>
              <input
                type="checkbox"
                className="toggle toggle-primary"
                {...register('ContinuidadLaboral')}
              />
              <span className="label-text font-bold text-md ml-3">Si</span>
            </label>
          </div>

        </div>

        <div className={
          cn('form-control w-full', {
            'invisible': !watch('ContinuidadLaboral') && !isComerciante,
            'hidden': isComerciante
          })
        }>
          <span className='label-text font-bold text-sm mb-2'>
            Fecha de finalización de tu trabajo anterior
          </span>

          <DateInput
            selected={dayjs(
              watch('FechaInicioTrabajoActual') ?? new Date()
            ).toDate()}
            maxDate={dayjs(watch('FechaInicioTrabajoActual')).subtract(1, 'days').toDate()}
            className='input input-bordered w-full bg-white'
            onChange={ e => { setValue('FechaFinTrabajoAnterior', e) } }
            placeholderText='01/02/2016'
          />

          {
            errors.FechaFinTrabajoAnterior &&
            <div className="label">
              <span className="label-text-alt text-error">
                  {errors.FechaFinTrabajoAnterior.message}
              </span>
            </div>
          }
        </div>

        <div
          className={
            cn('form-control w-full', {
              'invisible': !watch('ContinuidadLaboral'),
              'hidden': isComerciante
            })
        }>

          <span className='label-text font-bold text-sm mb-2'>
            Fecha de inicio de tu trabajo anterior
          </span>

          <DateInput
            selected={
              dayjs(
                watch('FechaFinTrabajoAnterior') !== ''
                  ? watch('FechaFinTrabajoAnterior')
                  : new Date()
              ).toDate()
            }
            maxDate={dayjs(watch('FechaFinTrabajoAnterior')).subtract(1, 'days').toDate()}
            className='input input-bordered w-full bg-white'
            onChange={ e => { setValue('FechaInicioTrabajoAnterior', e) } }
            placeholderText='01/02/2016'
          />

          {
            errors.FechaInicioTrabajoAnterior &&
            <div className="label">
              <span className="label-text-alt text-error">
                  {errors.FechaInicioTrabajoAnterior.message}
              </span>
            </div>
          }
        </div>
      </div>

    </div>
  )
}
