import { useState } from 'react'
import dayjs from 'dayjs'
import DatePicker, { registerLocale, type ReactDatePickerProps, type ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('es', es)

type PartialPickerProps = Omit<ReactDatePickerProps, 'onChange'>

interface Props extends PartialPickerProps {
  onChange: (date: string) => void
}

export const DateInput: React.FC<Props> = ({
  onChange,
  selected,
  maxDate,
  ...props
}) => {
  const [selectDate, setSelectDate] = useState(selected)

  const handleDateChange = (date: Date): void => {
    const dateParsed = dayjs(date).format('YYYY-MM-DD')
    setSelectDate(date)
    onChange(dateParsed)
  }

  return (
    <DatePicker
        // showFullMonthYearPicker={true}
        dateFormat='dd/MM/yyyy'
        onChange={handleDateChange}
        selected={selectDate}
        locale="es"
        maxDate={maxDate}
        renderCustomHeader={
            (props) => <CustomHeader
                maxYear={
                    maxDate?.getFullYear()
                      // ? maxDate.getFullYear()
                      // : dayjs().toDate().getFullYear()
                }
                {...props}
            />
        }
        {...props}
    />
  )
}

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  maxYear?: number
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  date,
  changeYear,
  changeMonth,
  maxYear
}) => {
  // const initYear = maxYear && isNaN(maxYear) ? new Date().getFullYear() : maxYear
  const initYear = maxYear
    ? (isNaN(maxYear) ? new Date().getFullYear() : maxYear)
    : new Date().getFullYear()

  const years = Array.from({ length: 40 }, (_, i) => initYear - i)

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  return (
    <div className='my-1 mx-2 flex justify-between gap-2'>
        <select
            className='select select-bordered bg-white select-sm w-full text-black'
            value={dayjs(date).format('YYYY')}
            onChange={({ target: { value } }) => { changeYear(+value) } }
        >
            {
                years.map((option) => (
                    <option
                        key={`year_input_${option}`}
                        value={option}
                    >
                        {option}
                    </option>
                ))
            }
        </select>

        <select
           className='select select-bordered bg-white select-sm w-full text-black'
           value={months[dayjs(date).month()]}
           onChange={ ({ target: { value } }) => {
             changeMonth(months.indexOf(value))
           }}
        >
            {
                months.map((option) => (
                    <option key={`month_input_${option}`} value={option}>
                        {option}
                    </option>
                ))
            }
        </select>
    </div>
  )
}
