import Joi from 'joi'
import esMessages from '../locales/validation/es'

const custom = Joi.defaults(schema => schema.options({
  messages: {
    es: esMessages
  },
  errors: {
    language: 'es'
  }
}))

export const schemaDatosPersonales = custom.object({
  Nombres: Joi.string().required().max(50).min(1),
  TipoIdentificacion: Joi.string().required().valid('CEDULA'),
  Identificacion: Joi.string().required().max(15).min(5),
  FechaNacimiento: Joi.string().required(),
  Celular: Joi.string().required().max(20),
  Correo: Joi.string().required().email({ tlds: false })
}).unknown(true)

const itemFinanciero = custom.object({
  Total: Joi.number().min(1).required(),
  Descripcion: Joi.string().required()
})

// const egresos = custom.object({
//   Total: Joi.number().min(1).required(),
//   Descripcion: Joi.string().required()
// })

const schemaDataFinanciera = custom.object({
  Egresos: Joi.array(),
  Ingresos: Joi.array().items(itemFinanciero)
})

export const schemaIngresos = custom.object({
  CiudadResidencia: Joi.string().required(),
  Actividad: Joi.number().required(),
  FechaInicioTrabajoActual: Joi.string().required(),
  FechaInicioTrabajoAnterior: Joi.string().allow(''),
  FechaFinTrabajoAnterior: Joi.string().allow(''),
  ContinuidadLaboral: Joi.boolean(),
  ComercioLocal: Joi.boolean(),
  DataFinanciera: schemaDataFinanciera
}).unknown(true)

export const schemaPropiedad = custom.object({
  ValorInmueble: Joi.number().min(80000000).required(),
  // Producto: Joi.array().items(
  //   Joi.object({
  //     code: Joi.string().required(),
  //     description: Joi.string().required()
  //   })
  // ).min(1).max(1).required(),
  Producto: Joi.string().required(),
  CiudadInmueble: Joi.string().required()
})
