import cn from 'classnames'
import { useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

interface Props {
  children: React.ReactNode
  open: boolean
  disableClickOutside?: boolean
  onClose: () => void
}

export const Modal: React.FC<Props> = ({ children, open, disableClickOutside = false, onClose }) => {
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    if (!(disableClickOutside ?? false)) {
      onClose()
    }
  })

  const modalClass = cn({
    'modal modal-bottom sm:modal-middle bg-white': true,
    'modal-open': open
  })

  return (
    <div className={modalClass}>
    <div className="modal-box bg-white" ref={ref}>{children}</div>
    </div>
  )
}
