import { useEffect } from 'react'

type PartialInputProps = Pick<
React.ComponentPropsWithRef<'input'>,
'className' | 'style'
>

interface Props extends PartialInputProps {
  value: string
  onChange: (value: string) => void
  size?: number
  validationPattern?: RegExp
  autoSubmit?: (value: string) => void
}

export const OtpInput: React.FC<Props> = ({
  size = 6,
  validationPattern = /[0-9]{1}/,
  onChange,
  className,
  value,
  autoSubmit,
  ...props
}) => {
  const fillArray = Array(size).fill('-')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const val = e.target.value

    if (!validationPattern.test(val) && val !== '') return

    const valArr = value.split('')
    valArr[index] = val
    const newVal = valArr.join('').slice(0, 6)
    onChange(newVal)

    if (val !== '') {
      const next = e.target.nextElementSibling as HTMLInputElement | null
      next?.focus()
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const current = e.currentTarget

    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      const prev = current.previousSibling as HTMLInputElement | null
      prev?.focus()
      prev?.setSelectionRange(0, 1)
      return
    }

    if (e.key === 'ArrowRight') {
      const prev = current.nextSibling as HTMLInputElement | null
      prev?.focus()
      prev?.setSelectionRange(0, 1)
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const val = e.clipboardData.getData('text').substring(0, size)
    onChange(val)
  }

  useEffect(() => {
    if ('OTPCredential' in window && autoSubmit) {
      navigator.credentials
        .get({
          // @ts-expect-error
          otp: { transport: ['sms'] }
        })
        .then((otp: any) => {
          autoSubmit(otp.code || '')
        })
        .catch(console.error)
    }
  }, [])

  return (
    <div className="flex justify-center gap-2 w-full bg-white">
        {
            fillArray.map((_, index) => {
              return (
                    <input
                        key={`otpInput-Key-${index}`}
                        {...props}
                        className={ className ?? 'input input-bordered px-0 text-center bg-white' }
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern={validationPattern.source}
                        maxLength={6}
                        value={value.at(index) ?? ''}
                        onChange={(e) => { handleInputChange(e, index) } }
                        onKeyUp={ handleKeyUp }
                        onPaste={ handlePaste }
                    />
              )
            })
        }
    </div>
  )
}
