import IconLogo from '../assets/icon_logo.svg'

export const Loading = (): JSX.Element => {
  return (
    <div className="app-loading">
    <div className="logo">
      <img
        className="w-full"
        src={IconLogo}
        alt="Loading"
      />
    </div>
    <svg className="spinner" viewBox="25 25 50 50">
      <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  </div>

  )
}
