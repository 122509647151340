import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyA6p0OXBuh6O7somH1M9eJYH3wjAviQlCI',
  authDomain: 'crediatlas-895ae.firebaseapp.com',
  projectId: 'crediatlas-895ae',
  storageBucket: 'crediatlas-895ae.appspot.com',
  messagingSenderId: '948292451696',
  appId: '1:948292451696:web:d19edb1da8842c6f5aa3b5',
  measurementId: 'G-6JWXTVL0GR'
}

const app = initializeApp(firebaseConfig)

export const firebaseAuth = getAuth(app)
