const es = {
  'alternatives.all': 'No coincide con todos los tipos requeridos',
  'alternatives.any': 'No coincide con ninguno de los tipos permitidos',
  'alternatives.match': 'No coincide con ninguno de los tipos permitidos',
  'alternatives.one': 'Coincide con más de un tipo permitido',
  'alternatives.types': 'Debe ser uno de {{#types}}',
  'any.custom': 'No pasó la validación personalizada porque {{#error.message}}',
  'any.default': 'Generó un error al ejecutar el método predeterminado',
  'any.failover': 'Generó un error al ejecutar el método de respaldo',
  'any.invalid': 'Contiene un valor no válido',
  'any.only': 'Debe ser {if(#valids.length == 1, "", "uno de ")}{{#valids}}',
  'any.ref': '{{#arg}} hace referencia a {{:#ref}} que {{#reason}}',
  'any.required': 'Es obligatorio',
  'any.unknown': 'No está permitido',
  'array.base': 'Debe ser un array',
  'array.excludes': 'Contiene un valor excluido',
  'array.hasKnown': 'No contiene al menos una coincidencia requerida para el tipo {:#patternLabel}',
  'array.hasUnknown': 'No contiene al menos una coincidencia requerida',
  'array.includes': 'No coincide con ninguno de los tipos permitidos',
  'array.includesRequiredBoth': 'No contiene {{#knownMisses}} y {{#unknownMisses}} otro(s) valor(es) requerido(s)',
  'array.includesRequiredKnowns': 'No contiene {{#knownMisses}}',
  'array.includesRequiredUnknowns': 'No contiene {{#unknownMisses}} valor(es) requerido(s)',
  'array.length': 'Debe contener {{#limit}} elementos',
  // 'array.length': 'Selecciona al menos una opción',
  'array.max': 'Debe contener menos o igual a {{#limit}} elementos',
  // 'array.min': 'Debe contener al menos {{#limit}} elementos',
  'array.min': 'Selecciona al menos una opción',
  'array.orderedLength': 'Debe contener como máximo {{#limit}} elementos',
  'array.sort': 'Debe estar ordenado en orden {#order} por {{#by}}',
  'array.sort.mismatching': 'No se puede ordenar debido a tipos incompatibles',
  'array.sort.unsupported': 'No se puede ordenar debido a un tipo no compatible {#type}',
  'array.sparse': 'No debe ser un elemento de array disperso',
  'array.unique': 'Contiene un valor duplicado',
  'binary.base': 'Debe ser un buffer o una cadena',
  'binary.length': 'Debe tener {{#limit}} bytes',
  'binary.max': 'Debe ser menor o igual a {{#limit}} bytes',
  'binary.min': 'Debe tener al menos {{#limit}} bytes',
  'boolean.base': 'Debe ser un booleano',
  'date.base': 'Debe ser una fecha válida',
  'date.format': 'Debe estar en formato {msg("date.format." + #format) || #format}',
  'date.greater': 'Debe ser mayor que {{:#limit}}',
  'date.less': 'Debe ser menor que {{:#limit}}',
  'date.max': 'Debe ser menor o igual a {{:#limit}}',
  'date.min': 'Debe ser mayor o igual a {{:#limit}}',
  'date.format.iso': 'fecha ISO 8601',
  'date.format.javascript': 'marca de tiempo o número de milisegundos',
  'date.format.unix': 'marca de tiempo o número de segundos',
  'function.arity': 'Debe tener una aridad de {{#n}}',
  'function.class': 'Debe ser una clase',
  'function.maxArity': 'Debe tener una aridad menor o igual a {{#n}}',
  'function.minArity': 'Debe tener una aridad mayor o igual a {{#n}}',
  'object.and': 'Contiene {{#presentWithLabels}} sin sus compañeros requeridos {{#missingWithLabels}}',
  'object.assert': 'No es válido porque {if(#subject.key, `"` + #subject.key + `" no pasó la prueba de afirmación`, #message || "la afirmación falló")}',
  'object.base': 'Debe ser del tipo {{#type}}',
  'object.instance': 'Debe ser una instancia de {{:#type}}',
  'object.length': 'Debe tener {{#limit}} clave{if(#limit == 1, "", "s")}',
  'object.max': 'Debe tener menos o igual a {{#limit}} clave{if(#limit == 1, "", "s")}',
  'object.min': 'Debe tener al menos {{#limit}} clave{if(#limit == 1, "", "s")}',
  'object.missing': 'Debe contener al menos una de {{#peersWithLabels}}',
  'object.nand': '{{:#mainWithLabel}} no debe existir simultáneamente con {{#peersWithLabels}}',
  'object.oxor': 'Contiene un conflicto entre pares exclusivos opcionales {{#peersWithLabels}}',
  'object.pattern.match': 'las claves de No cumplieron con los requisitos del patrón',
  'object.refType': 'Debe ser una referencia de Joi',
  'object.regex': 'Debe ser un objeto RegExp',
  'object.rename.multiple': 'No puede renombrar {{:#from}} porque las renombradas múltiples están desactivadas y otra clave ya fue renombrada a {{:#to}}',
  'object.rename.override': 'No puede renombrar {{:#from}} porque la anulación está desactivada y el destino {{:#to}} ya existe',
  'object.schema': 'Debe ser un esquema Joi del tipo {{#type}}',
  'object.unknown': 'No está permitido',
  'object.with': '{{:#mainWithLabel}} falta el compañero requerido {{:#peerWithLabel}}',
  'object.without': '{{:#mainWithLabel}} entra en conflicto con el compañero prohibido {{:#peerWithLabel}}',
  'object.xor': 'Contiene un conflicto entre pares exclusivos {{#peersWithLabels}}',
  'number.base': 'Debe ser un número',
  'number.greater': 'Debe ser mayor que {{#limit}}',
  'number.infinity': 'No puede ser infinito',
  'number.integer': 'Debe ser un número entero',
  'number.less': 'Debe ser menor que {{#limit}}',
  'number.max': 'Debe ser menor o igual a {{#limit}}',
  'number.min': 'Debe ser mayor o igual a {{#limit}}',
  'number.multiple': 'Debe ser un múltiplo de {{#multiple}}',
  'number.negative': 'Debe ser un número negativo',
  'number.port': 'Debe ser un puerto válido',
  'number.positive': 'Debe ser un número positivo',
  'number.precision': 'No debe tener más de {{#limit}} lugares decimales',
  'number.unsafe': 'Debe ser un número seguro',
  'string.alphanum': 'Solo debe contener caracteres alfanuméricos',
  'string.base': 'Debe ser una cadena',
  'string.base64': 'Debe ser una cadena base64 válida',
  'string.creditCard': 'Debe ser una tarjeta de crédito',
  'string.dataUri': 'Debe ser una cadena dataUri válida',
  'string.domain': 'Debe contener un nombre de dominio válido',
  'string.email': 'Debe ser un correo electrónico válido',
  'string.empty': 'No se permite estar vacío',
  'string.guid': 'Debe ser un GUID válido',
  'string.hex': 'Solo debe contener caracteres hexadecimales',
  'string.hexAlign': 'la representación descodificada en hexadecimal de Debe estar alineada por byte',
  'string.hostname': 'Debe ser un nombre de host válido',
  'string.ip': 'Debe ser una dirección IP válida con un CIDR de {{#cidr}}',
  'string.ipVersion': 'Debe ser una dirección IP válida de una de las siguientes versiones {{#version}} con un CIDR de {{#cidr}}',
  'string.isoDate': 'Debe estar en formato ISO',
  'string.isoDuration': 'Debe ser una duración ISO 8601 válida',
  'string.length': 'la longitud de Debe ser de {{#limit}} caracteres',
  'string.lowercase': 'Solo debe contener caracteres en minúscula',
  'string.max': 'la longitud de Debe ser menor o igual a {{#limit}} caracteres',
  'string.min': 'la longitud de Debe ser de al menos {{#limit}} caracteres',
  'string.normalize': 'Debe estar normalizado en Unicode en la forma {{#form}}',
  'string.token': 'Solo debe contener caracteres alfanuméricos y de subrayado',
  'string.pattern.base': 'Con el valor {:[.]} no coincide con el patrón requerido: {{#regex}}',
  'string.pattern.name': 'Con el valor {:[.]} no coincide con el patrón {{#name}}',
  'string.pattern.invert.base': 'Con el valor {:[.]} coincide con el patrón invertido: {{#regex}}',
  'string.pattern.invert.name': 'Con el valor {:[.]} coincide con el patrón invertido {{#name}}',
  'string.trim': 'No debe tener espacios en blanco al principio o al final',
  'string.uri': 'Debe ser una uri válida',
  'string.uriCustomScheme': 'Debe ser una uri válida con un esquema que coincida con el patrón {{#scheme}}',
  'string.uriRelativeOnly': 'Debe ser una uri relativa válida',
  'string.uppercase': 'Solo debe contener caracteres en mayúscula',
  'symbol.base': 'Debe ser un símbolo',
  'symbol.map': 'Debe ser uno de {{#map}}'
}

export default es
