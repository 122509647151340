import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

import { useFormStore } from '../../../stores/form.store'
import { useAuthState } from 'react-firebase-hooks/auth'
import { firebaseAuth } from '../../../firebase/setup'

import { Loading } from '../../../components'

export const Resultado = (): JSX.Element => {
  const initialized = useRef(true)
  const solicitantes = useFormStore(state => state.solicitantes)
  const analisisCredito = useFormStore(state => state.analisisCredito)
  const idOrigen = useFormStore(state => state.idOrigen)

  const [loading, setLoading] = useState(true)
  const [user, loadingUser] = useAuthState(firebaseAuth)

  const [cotizacionExitosa, setCotizacionExitosa] = useState(true)

  useEffect(() => {
    if (initialized.current) {
      // const params = new URLSearchParams(window.location.search)

      // const origen = params.get('idOrigen') ?? ''

      const sendRequestCotizador = async (): Promise<void> => {
        const accessToken = await user?.getIdToken() ?? ''

        const Solicitantes = Object.values(solicitantes).map(e => {
          const egresos = e.DataFinanciera.Egresos
          const newEgresos = egresos.map(e => ({ Total: e.Endeudamiento, Descripcion: e.Descripcion }))
          const comercioLocal = e.ComercioLocal
          const actividad = e.Actividad

          return {
            ...e,
            Actividad: comercioLocal ? actividad : 10,
            DataFinanciera: {
              Ingresos: e.DataFinanciera.Ingresos,
              Egresos: newEgresos
            }
          }
        })

        const { ValorInmueble, CiudadInmueble, Producto } = analisisCredito
        const AnalisisCredito = {
          MontoSolicitado: 0,
          ValorInmueble,
          CiudadInmueble,
          Plazo: 25,
          Bancos: [
            {
              id: import.meta.env.VITE_ID_SCOTIA,
              producto: Producto
            }
          ]
        }

        const DatosCotizacion = {
          TipoCotizacion: Solicitantes.length > 1 ? 'MANCUMUNADO' : 'INDIVIDUAL',
          IdOrigen: idOrigen
        }

        const payload = { AnalisisCredito, Solicitantes, DatosCotizacion }

        try {
          await axios.post(`${import.meta.env.VITE_API_COTIZADOR}/cotizar`, payload, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          })
          setCotizacionExitosa(true)
        } catch (err) {
          console.error(err)
          setCotizacionExitosa(false)
        } finally {
          setLoading(false)
        }
      }

      if (!loadingUser) {
        void sendRequestCotizador()
      }
      initialized.current = false
    }
  }, [loadingUser])

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="py-8 px-10 h-[100%] flex flex-col justify-center items-center mt-auto">
        {
          cotizacionExitosa
            ? (<>
                <FontAwesomeIcon
                  className='text-6xl text-green-600'
                  icon={faCircleCheck}
                />

                <h2 className='text-xl text-center mt-4'>
                  <strong className='text-2xl'>Genial!!</strong> <br />
                  Tu solicitud ha sido recibida. <br />
                  Pronto recibirás un correo con el resultado de tu cotización.
                </h2>
              </>)
            : (<>

              <FontAwesomeIcon
                className='text-6xl text-red-600'
                icon={faCircleExclamation}
              />

              <h2 className='text-xl text-center mt-4'>
                <strong className='text-2xl'>Ups!!</strong> <br />
                Lamentamos informarte que algo salió mal. <br />
                Nuestro equipo ya está al tanto y resolveremos tu problema pronto.
              </h2>
            </>)
        }

    </div>
  )
}
