import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useCountdown } from 'usehooks-ts'
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import {
  useNavigate
  // useSearchParams
} from 'react-router-dom'

import { SweetAlert } from '../utils'
import { RecaptchaVerifier, type ConfirmationResult, signInWithPhoneNumber } from 'firebase/auth'
import { firebaseAuth } from '../firebase/setup'

interface Auth {
  phone: string | undefined
  otp: string
  timeWaitingToNewSMS: number
  sendingOtp: boolean

  setOtp: (otp: string) => void
  setPhone: (phone: string) => void
  handleSubmitSms: () => Promise<boolean>
  handleValidateOtp: () => Promise<void>
  handleResetOtp: () => Promise<void>
}

export const useLogin = (): Auth => {
  const navigate = useNavigate()
  // const [searchParams] = useSearchParams()
  const [sendingOtp, setSendingOtp] = useState(false)

  const [phone, setPhone] = useState<string | undefined>('')
  const [otp, setOtp] = useState<string>('')
  const [reloadCaptcha, setReloadCaptcha] = useState(false)

  const [otpConfirmation, setOtpConfirmation] = useState<ConfirmationResult | undefined>(undefined)

  const [count, { startCountdown, resetCountdown }] = useCountdown({ countStart: 60, intervalMs: 1000 })

  const handleSubmitSms = async (): Promise<boolean> => {
    if (phone === undefined) return false

    if (!isValidPhoneNumber(phone)) {
      await Swal.fire({
        title: 'Teléfono incorrecto',
        text: 'Al parecer el número que has ingresado es incorrecto. Por favor corrígelo.',
        icon: 'warning'
      })
      return false
    }

    const { isConfirmed } = await Swal.fire({
      title: 'Te mandaremos un SMS con un código.',
      html: `
          ¿Tu número <strong>${formatPhoneNumberIntl(phone)}</strong> es correcto? 
        `,
      showCancelButton: true,
      showConfirmButton: true,
      customClass: {
        actions: 'flex-row-reverse gap-12 mt-5'
      },
      cancelButtonText: 'Corregir',
      confirmButtonText: 'Continuar'
    })

    try {
      if (isConfirmed) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const otpConfi = await signInWithPhoneNumber(firebaseAuth, phone, window.recaptchaVerifier!)
        setOtpConfirmation(otpConfi)
        startCountdown()
        return true
      }
      return false
    } catch (err) {
      console.error(err)
      window.grecaptcha?.reset(window.recaptchaWidgetId)
      setReloadCaptcha(true)
      await SweetAlert({})
      return false
    }
  }

  const handleValidateOtp = async (): Promise<void> => {
    if ((otp.length < 6) || (otpConfirmation === undefined)) return

    setSendingOtp(true)

    try {
      await otpConfirmation.confirm(otp).then(() => {
        navigate({
          pathname: '/form'
          // search: idOrigen ? `?idOrigen=${idOrigen}` : ''
        })
      })
    } catch (err: any) {
      switch (err.code) {
        case 'auth/invalid-verification-code':
          await SweetAlert({ icon: 'warning', message: 'El código de verificación es incorrecto.' })
          break
        case 'auth/invalid-phone-number':
          await SweetAlert({ icon: 'warning', message: 'El número de teléfono que has ingresado no es válido.' })
          break
        case 'auth/missing-phone-number':
          await SweetAlert({ icon: 'warning', message: 'Es necesario que ingreses un número de teléfono para poder continuar.' })
          break
        case 'auth/quota-exceeded':
          await SweetAlert({ icon: 'warning', message: 'Has excedido el limite de solicitudes de verificación vía SMS, puedes intentar más tarde.' })
          break
        case 'auth/captcha-check-failed':
          await SweetAlert({ icon: 'warning', message: 'Ha fallado o ha expirado la verificación CAPTCHA, puedes refrescar la pagina y intentarlo de nuevo.' })
          break
        case 'auth/too-many-requests':
          await SweetAlert({ icon: 'warning', message: 'Has excedido el numero de solicitudes para obtener un codigo, intenta mas tarde.' })
          break
        default:
          await SweetAlert({})
      }
    } finally {
      setSendingOtp(false)
    }
  }

  const handleResetOtp = async (): Promise<void> => {
    if ((phone === undefined) || (count > 0)) return

    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const otpConfi = await signInWithPhoneNumber(firebaseAuth, phone, window.recaptchaVerifier!)
      setOtpConfirmation(otpConfi)

      resetCountdown()
      startCountdown()
    } catch (err) {
      console.error(err)
      await SweetAlert({})
    }
  }

  useEffect(() => {
    const renderCaptcha = async (): Promise<void> => {
      if (window.recaptchaVerifier === undefined) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          firebaseAuth,
          'recaptcha-container',
          {
            size: 'invisible',
            'expired-callback': () => {
              window.recaptchaVerifier = undefined
              window.location.reload()
            }
          }
        )

        await window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaWidgetId = widgetId
        })
      }
    }

    void renderCaptcha()

    // return () => {
    //   window.recaptchaVerifier?.clear()
    // }
  }, [reloadCaptcha])

  return {
    phone,
    otp,
    timeWaitingToNewSMS: count,
    sendingOtp,

    setOtp,
    setPhone,
    handleSubmitSms,
    handleValidateOtp,
    handleResetOtp
  }
}
