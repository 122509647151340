import { Outlet, useNavigate } from 'react-router-dom'
import Headroom from 'react-headroom'
import logo from '../assets/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth'
import Swal from 'sweetalert2'
import cn from 'classnames'
import { firebaseAuth } from '../firebase/setup'
import { useFormStore } from '../stores/form.store'

interface Props {
  children?: React.ReactNode
}

export const DefaultLayout: React.FC<Props> = ({ children = false }) => {
  const [user] = useAuthState(firebaseAuth)
  const [signOut, loading] = useSignOut(firebaseAuth)

  const resetStore = useFormStore(state => state.reset)

  const navigate = useNavigate()

  const handleClickLogout = async (): Promise<void> => {
    const { isConfirmed } = await Swal.fire({
      icon: 'question',
      title: 'Estas Seguro?',
      showCancelButton: true,
      showConfirmButton: true,
      customClass: {
        actions: 'flex-row-reverse gap-12 mt-5'
      },
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Salir'
    })

    if (!isConfirmed) return

    resetStore()
    localStorage.removeItem('form-store')
    await signOut()
    window.recaptchaVerifier = undefined

    navigate('/')
  }

  return (
    <main className='w-full relative'>

        <Headroom className="bg-white">
          <div className="navbar bg-white w-full shadow-xl">
            <div className="flex-1 justify-start">
              <img src={logo}
                className="w-24 md:w-24 lg:w-32"
                alt="Vivienda Total Logo"
              />
            </div>

            <a
              className={cn('btn btn-outline btn-primary mx-2', {
                'hidden': user
              })}
              href="http://asesores.crediatlas.com">
              Entrar como Asesor
            </a>

            <button
              onClick={handleClickLogout}
              className={cn('btn btn-md mx-5 btn-outline btn-error', {
                'hidden': loading || !user
              })}
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
              />
              Logout
            </button>
          </div>

        </Headroom>

        <article className='pb-4'>
          {
            children ?? <Outlet/>
          }
        </article>

    </main>
  )
}
