import { useEffect, useMemo, useRef, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import axios from 'axios'
import CurrencyInput from 'react-currency-input-field'

import { AutoComplete } from '../../../components'
import { meiliSearch } from '../../../utils'
import { schemaPropiedad } from '../../../validation/steps'
import { type AnalisisCredito, type Product } from '../../../types'
import { useFormStore } from '../../../stores/form.store'
import { useWizard } from 'react-use-wizard'

interface MeiliCiudad {
  name: string
  tope_vis: number
  tope_vip: number
}

type FormData = AnalisisCredito

export const Propiedad = (): JSX.Element => {
  const initialized = useRef(true)

  const setAnalisisCredito = useFormStore(state => state.setAnalisisCredito)
  const analisisCredito = useFormStore(state => state.analisisCredito)
  const tipoInmueble = useFormStore(state => state.tipoInmueble)
  const setTipoInmueble = useFormStore(state => state.setTipoInmueble)

  const [suggestions, setSuggestions] = useState<MeiliCiudad[]>([])
  const [{ maxVIP, maxVIS }, setMaxValorPropiedad] = useState({ maxVIP: 0, maxVIS: 0 })
  const [products, setProducts] = useState<Product[]>([])

  const { handleStep } = useWizard()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm<FormData>({
    resolver: joiResolver(schemaPropiedad),
    defaultValues: useMemo(() => analisisCredito, [analisisCredito])
  })

  const handleSearchCiudades = async (searchTerm: string): Promise<void> => {
    if (!searchTerm) return

    const results = await meiliSearch<MeiliCiudad[]>({
      index: 'scotia_ciudades',
      attributesToRetrieve: 'name,tope_vis,tope_vip',
      attributesToSearchOn: 'name',
      filter: 'target=1',
      searchTerm
    })

    setSuggestions(results)
  }

  const handleMaxValorProp = (city: string): void => {
    const ciudadInmueble = suggestions.find(e => e.name === city)
    if (!ciudadInmueble) return
    const maxVIS = ciudadInmueble.tope_vis * import.meta.env.VITE_SMMLV
    const maxVIP = ciudadInmueble.tope_vip * import.meta.env.VITE_SMMLV

    setMaxValorPropiedad({
      maxVIP,
      maxVIS
    })
  }

  const handleTipoProp = (valorPropiedad: number): void => {
    if (valorPropiedad < 80000000) return

    if (valorPropiedad <= maxVIP) {
      setTipoInmueble('VIP')
      return
    }

    if ((valorPropiedad > maxVIP) && (valorPropiedad <= maxVIS)) {
      setTipoInmueble('VIS')
      return
    }

    setTipoInmueble('NO VIS')
  }

  const onSubmit: SubmitHandler<FormData> = (data): void => {
    setAnalisisCredito(data)
  }

  handleStep(async () => {
    await new Promise((resolve) => {
      void handleSubmit(
        async (data) => {
          await onSubmit(data)
          resolve(data)
        }
      )()
    })
  })

  useEffect(() => {
    if (initialized.current) {
      if (analisisCredito) {
        reset(analisisCredito)
      }

      initialized.current = false
    }
    const getProducts = async (): Promise<void> => {
      const { data: { data } } = await axios.post< { data: Product[] } >(`${import.meta.env.VITE_API_COTIZADOR}/listProducts`, {
        tipoInmueble: tipoInmueble || 'VIS'
      })

      setProducts(data)
    }

    // if (tipoPropiedad) {
    void getProducts()
    // }
  }, [tipoInmueble, analisisCredito])

  return (
    <div className='grid grid-cols-1 justify-items-center gap-4 py-8 px-10'>
      <div className="form-control w-full md:w-1/2">
        <span className='label-text font-bold text-md mb-2'>¿Ciudad del inmueble? *</span>

        <AutoComplete
          defaultValue={getValues('CiudadInmueble')}
          items={suggestions.map(e => e.name)}
          onInput={handleSearchCiudades}
          onSelect={(city) => {
            setValue('CiudadInmueble', city)
            handleMaxValorProp(city)
            handleTipoProp(getValues('ValorInmueble'))
          }}
        />

        {
          errors.CiudadInmueble &&
          <div className="label">
            <span className="label-text-alt text-error">
                {errors.CiudadInmueble.message}
            </span>
          </div>
        }
      </div>

      <div className="form-control w-full md:w-1/2">
        <span className='label-text font-bold text-md mb-2'>Valor comercial del inmueble *</span>

        <CurrencyInput
          defaultValue={getValues('ValorInmueble')}
          placeholder='$ 100,000,000.00'
          decimalsLimit={2}
          allowNegativeValue={false}
          prefix='$ '
          className='input input-bordered w-full bg-white'
          onValueChange={(value) => {
            const input = value ? +value : 0
            setValue('ValorInmueble', input)
            handleTipoProp(input)
          }}
        />

        {
          errors.ValorInmueble &&
          <div className="label">
            <span className="label-text-alt text-error">
                {errors.ValorInmueble.message}
            </span>
          </div>
        }

      </div>

      <div className="form-control w-full md:w-1/2" >
        <span className='label-text font-bold text-md mb-2'>Deseas tu crédito para *</span>

        {
          products.length > 0
            ? (
                <select
                  defaultValue={getValues('Producto')}
                  className='select select-bordered bg-white'
                  {...register('Producto')}
                >
                  {
                    getValues('Producto').length <= 0 &&
                    <option value="" disabled>Selecciona una opción</option>
                  }
                  {
                    products.map((product, index) => (
                      <option
                        // selected={ product.code === (getValues('Producto.0.code') || '') }
                        key={`product_opt_${index}`}
                        value={product.code}
                      >{product.description}</option>
                    ))
                  }
                </select>
              )
            : <span>Cargando productos...</span>
        }

        {
          errors.Producto &&
          <div className="label">
            <span className="label-text-alt text-error">
                {errors.Producto.message}
            </span>
          </div>
        }
      </div>

    </div>
  )
}
