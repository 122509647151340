import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'

// import App from '../App'
import { DefaultLayout } from '../layouts/DefaultLayout'

import { firebaseAuth } from '../firebase/setup'
import { Home, Form } from '../pages'
import { Loading } from '../components'

const ProtectedRoutes = (): JSX.Element | null => {
  const [user, loading] = useAuthState(firebaseAuth)

  if (loading) {
    return <Loading />
  }

  return (user != null) && !loading ? <Outlet /> : <Navigate to="/" replace />
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout> <Home /> </DefaultLayout>
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: 'form',
        element: <DefaultLayout> <Form/> </DefaultLayout>
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
])
