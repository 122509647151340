import { useWizard } from 'react-use-wizard'
import cn from 'classnames'

export const Footer = (): JSX.Element => {
  const { isFirstStep, previousStep, nextStep, isLastStep } = useWizard()

  const handleStepBack = (): void => {
    previousStep()
  }

  return (
    <div className="flex justify-between w-full mt-auto px-10 py-6 ">
        <button
            onClick={handleStepBack}
            className={cn('btn dark:bg-white dark:text-black', {
              'invisible': isFirstStep || isLastStep
            })}
            >
            Volver
        </button>

        <button
            onClick={nextStep}
            className={cn('btn btn-primary text-white bg-light-red border-light-red', {
              'invisible': isLastStep
            })}>
            Continuar
        </button>
    </div>
  )
}
