import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormStore } from '../../../stores/form.store'
import { currencyFormat } from '../../../utils'
import { useWizard } from 'react-use-wizard'

export const Codeudores = (): JSX.Element => {
  const currentTarget = useFormStore(state => state.targetId)
  const solicitantes = useFormStore(state => state.solicitantes)
  const setTarget = useFormStore(state => state.setTarget)
  const setSolicitante = useFormStore(state => state.setSolicitante)
  const removeSolicitante = useFormStore(state => state.removeSolicitante)

  const { goToStep } = useWizard()

  const handleEditSolicitante = (idTarget: string): void => {
    setTarget(idTarget)
    goToStep(0)
  }

  const handleDeleteSolicitante = (idTarget: string): void => {
    if (idTarget === currentTarget) {
      const newTarget = Object.keys(solicitantes).filter(id => id !== idTarget)[0]
      setTarget(newTarget)
    }
    removeSolicitante(idTarget)
  }

  const handleAddNewSolicitante = (): void => {
    const id = setSolicitante('CODEUDOR', '')
    setTarget(id)
    goToStep(0)
  }

  return (
    <div className='flex flex-col justify-center gap-5 items-center py-8 px-10'>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

        {
          Object.keys(solicitantes).map((id) => {
            const solicitante = solicitantes[id]

            const { Nombres, DataFinanciera, TipoSolicitante } = solicitante

            const { Ingresos, Egresos } = DataFinanciera

            const totalIngresos = Ingresos.reduce((total, ingreso) => {
              const sumTotal = total += ingreso.Total
              return sumTotal
            }, 0)

            const totalEgresos = Egresos.reduce((total, egreso) => {
              const sumTotal = total += egreso.Endeudamiento
              return sumTotal
            }, 0)

            return (
              <div key={id} className="card bg-gray-100 cursor-pointer text-center">
                <div className="card-body py-4 gap-0">
                  <span className='font-bold uppercase'>
                    <span className='text-primary'>{TipoSolicitante}</span> <br />
                    { Nombres.length > 15 ? `${Nombres.substring(0, 15)}...` : Nombres }
                  </span>
                  <span>Ingresos: <b>$ {currencyFormat(totalIngresos)}</b></span>
                  <span>Egresos: <b>$ {currencyFormat(totalEgresos)}</b></span>

                  <div className="flex justify-between items-center mt-2 gap-3">

                    <button
                      onClick={() => { handleDeleteSolicitante(id) }}
                      disabled={ TipoSolicitante === 'TITULAR' }
                      className='btn btn-sm btn-outline btn-error'>
                      Eliminar <FontAwesomeIcon icon={faTrash} />
                    </button>

                    <button
                    onClick={() => { handleEditSolicitante(id) }}
                    className="btn btn-sm btn-error font-bold text-white">
                      Editar <FontAwesomeIcon icon={faEdit} />
                    </button>

                  </div>

                </div>
              </div>
            )
          })
        }

      </div>

      <h2 className=''>Puedes editar tu Información o continuar el proceso.</h2>

      <button
      onClick={() => { handleAddNewSolicitante() }}
      className='flex btn gap-5 btn-error text-white'>
        <div className='max-w-sm font-bold uppercase'>
          Agregar Codeudor <br />
        </div>

        <FontAwesomeIcon icon={faPlus} />

      </button>

      <h2 className='text-center max-w-lg'>
        Recuerda que tu codeudor debe ser tu cónyuge o tener un lazo de parentesco en primer o segundo grado.
      </h2>

    </div>
  )
}
